import { FinancingContractType } from '@retail/order-constants';

const range = (start: number, end: number, step: number) => {
  const result = [];
  for (let i = start; i < end; i += step) {
    result.push(i);
  }
  return result;
};

export const getFinancingPeriods = ({
  min,
  max,
  step,
}: {
  min: number;
  max: number;
  step: number;
}) => {
  if (!min || !max) {
    return [];
  }

  return range(min, max + 1, step).sort((a, b) => b - a);
};

export const cappedNumber = (min: number, max: number, value: number) =>
  Math.min(Math.max(value, min), max);

export const getWithDiscountByContractType = (contractType: string) =>
  ({
    [FinancingContractType.CLASSIC]: null,
    [FinancingContractType.CLASSIC_WITHOUT_DISCOUNT]: false,
    [FinancingContractType.CLASSIC_WITH_DISCOUNT]: true,
  })[contractType];
