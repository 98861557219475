import { useCallback } from 'react';

import { FormValues } from '~/features/CustomerActionRequiredModal/types';
import {
  useSaveNewFinancingTermsMutation,
  NewFinancingTermsSaveProjectionInput,
} from '~/apollo/gql-types';

export const useSaveFinancingTerms = ({
  financingApplicationId,
}: {
  financingApplicationId: string;
}) => {
  const [saveNewTerms] = useSaveNewFinancingTermsMutation();

  return useCallback(
    async (newTerms: Omit<FormValues, 'comment' | 'reason'>) => {
      await saveNewTerms({
        variables: {
          terms: {
            ...newTerms,
            financingApplicationId,
          } as NewFinancingTermsSaveProjectionInput,
        },
      });
    },
    [saveNewTerms, financingApplicationId],
  );
};
