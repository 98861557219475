import { InputNumber, InputNumberProps } from 'antd';
import {
  CurrencyCodes,
  getCurrencySymbolByCode,
  unitsFromMajorToMinor,
  unitsFromMinorToMajor,
} from '@retail/currency';

interface AmountInputProps extends InputNumberProps {
  currencyCode: CurrencyCodes | undefined;
  disabled?: boolean;
  qaSelector: string;
}

type ValueType = string | number;

const converters = {
  formatter: (value: ValueType) =>
    value == null || value === ''
      ? ''
      : String(unitsFromMinorToMajor(Number(value))),
  parser: (value: string | undefined) =>
    value == null ? NaN : unitsFromMajorToMinor(Number(value)),
};

export const AmountInput = ({
  currencyCode,
  qaSelector,
  ...props
}: AmountInputProps) => {
  return (
    <InputNumber
      style={{ width: '100%' }}
      addonBefore={
        currencyCode ? getCurrencySymbolByCode(currencyCode) : undefined
      }
      data-qa-selector={qaSelector}
      {...converters}
      {...props}
    />
  );
};
