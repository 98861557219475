import { PropsWithChildren } from 'react';
import { ApolloProvider } from '@apollo/client';
import { ConfigProvider } from 'antd';
import { useDevAuthToken } from '@retail/dev-auth';

import { client } from '~/apollo/gql-client';
import { ANTD_EXTERNAL_VERSION_LESS } from '~/constants/antd';
import { UserProvider } from '~/providers/UserProvider';

const FORM_CONFIG = {
  validateMessages: {
    required: 'Field is required',
  },
};

export const RootContext = ({ children }: PropsWithChildren<object>) => {
  // eslint-disable-next-line no-undef
  if (__IS_DEV_AUTH__) {
    // We call hook depending on build-time constant condition
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useDevAuthToken();
  }

  return (
    <ConfigProvider prefixCls={ANTD_EXTERNAL_VERSION_LESS} form={FORM_CONFIG}>
      <ApolloProvider client={client}>
        <UserProvider>{children}</UserProvider>
      </ApolloProvider>
    </ConfigProvider>
  );
};
