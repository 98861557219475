import { FinancingStatus } from '@retail/order-constants';

type StatusCondition =
  | {
      nextStatuses: string[];
    }
  | {
      previousStatuses: string[];
      nextStatuses: string[];
    }[];

const transitionMap: Record<string, StatusCondition> = {
  [FinancingStatus.PENDING]: { nextStatuses: [] },
  [FinancingStatus.VALIDATION]: {
    nextStatuses: [
      FinancingStatus.CUSTOMER_ACTION_REQUIRED,
      FinancingStatus.SUBMITTED_TO_BANK,
      FinancingStatus.CANCELED,
      FinancingStatus.REJECTED,
    ],
  },
  [FinancingStatus.CUSTOMER_ACTION_REQUIRED]: [
    {
      previousStatuses: [FinancingStatus.VALIDATION],
      nextStatuses: [FinancingStatus.VALIDATION, FinancingStatus.CANCELED],
    },
    {
      previousStatuses: [FinancingStatus.SUBMITTED_TO_BANK],
      nextStatuses: [
        FinancingStatus.SUBMITTED_TO_BANK,
        FinancingStatus.CANCELED,
      ],
    },
    {
      previousStatuses: [FinancingStatus.PRE_APPROVED],
      nextStatuses: [FinancingStatus.PRE_APPROVED, FinancingStatus.CANCELED],
    },
    {
      previousStatuses: [FinancingStatus.APPROVED],
      nextStatuses: [FinancingStatus.APPROVED, FinancingStatus.CANCELED],
    },
  ],
  [FinancingStatus.SUBMITTED_TO_BANK]: {
    nextStatuses: [
      FinancingStatus.CUSTOMER_ACTION_REQUIRED,
      FinancingStatus.APPROVED,
      FinancingStatus.PRE_APPROVED,
      FinancingStatus.CANCELED,
      FinancingStatus.REJECTED,
    ],
  },
  [FinancingStatus.APPROVED]: {
    nextStatuses: [
      FinancingStatus.CUSTOMER_ACTION_REQUIRED,
      FinancingStatus.PRE_APPROVED,
      FinancingStatus.CANCELED,
      FinancingStatus.REJECTED,
    ],
  },
  [FinancingStatus.PRE_APPROVED]: {
    nextStatuses: [
      FinancingStatus.CUSTOMER_ACTION_REQUIRED,
      FinancingStatus.APPROVED,
      FinancingStatus.CANCELED,
      FinancingStatus.REJECTED,
    ],
  },
  [FinancingStatus.REJECTED]: {
    nextStatuses: [
      FinancingStatus.VALIDATION,
      FinancingStatus.SUBMITTED_TO_BANK,
      FinancingStatus.PRE_APPROVED,
      FinancingStatus.APPROVED,
      FinancingStatus.CANCELED,
    ],
  },
  [FinancingStatus.CANCELED]: {
    nextStatuses: [
      FinancingStatus.VALIDATION,
      FinancingStatus.SUBMITTED_TO_BANK,
      FinancingStatus.PRE_APPROVED,
      FinancingStatus.APPROVED,
      FinancingStatus.REJECTED,
    ],
  },
};

interface IsValidTransitionParams {
  previousStatus: undefined | null | string;
  currentStatus: string;
  nextStatus: string;
}

export const isValidTransition = ({
  previousStatus,
  currentStatus,
  nextStatus,
}: IsValidTransitionParams): boolean => {
  const conditions = [transitionMap[currentStatus] ?? []].flat();

  return conditions.some((condition) => {
    const matchesPreviousStatus =
      !('previousStatuses' in condition) ||
      (previousStatus && condition.previousStatuses.includes(previousStatus));

    return matchesPreviousStatus && condition.nextStatuses.includes(nextStatus);
  });
};
