import { useMemo } from 'react';
import { FinancingType, FinancingProductType } from '@retail/order-constants';

import {
  useFinancingPlansHistoryQuery,
  FinancingFragment as Financing,
} from '~/apollo/gql-types';
import { PERMISSION } from '~/constants/permission';
import { DOMAIN } from '~/constants/domain';
import useCheckPermissions from '~/hooks/acl/useCheckPermissions';

import { Section } from './Section';

interface AxaPolicyNotificationsProps {
  financing: Financing;
}

export function AxaPolicyNotifications({
  financing,
}: AxaPolicyNotificationsProps) {
  const { financingId, financingType } = financing ?? {};
  const { isAllowed: hasViewPermission } = useCheckPermissions({
    allow: PERMISSION.QUERY_ORDERS_AXA_POLICY_NOTIFICATIONS,
    domain: DOMAIN.ORDER_MANAGEMENT,
  });
  const { data } = useFinancingPlansHistoryQuery({
    skip: !hasViewPermission || financingType !== FinancingType.INTERNAL,
    context: {
      headers: {
        'access-domain': DOMAIN.ORDER_FINANCING_RISK_MANAGEMENT,
      },
    },
    variables: {
      orderFinancingId: financingId,
    },
  });

  const isApplicable = useMemo(
    () =>
      data?.financingPlansHistory
        ?.find((plan) => plan.isSelectedForRetailRiskCalculation)
        ?.retailFinancingTiers?.find((tier) => tier.isSelected)
        ?.products?.some((product) =>
          [FinancingProductType.PPI, FinancingProductType.GAP].includes(
            product.type as FinancingProductType,
          ),
        ),
    [data],
  );

  return isApplicable ? <Section orderId={financing.orderId} /> : null;
}
