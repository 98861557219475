export const selectQaSelectors = (qaSelector: string) => ({
  'data-qa-selector': `select_${qaSelector}`,
  dropdownClassName: `QA_ID_dropdown_${qaSelector}`,
});

export const addQaIdForSelectOptions = <T extends { value: string | number }>(
  options: T[],
): T[] =>
  options.map((it) => ({
    ...it,
    'data-qa-selector': it.value,
  }));
