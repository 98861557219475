import { endOfDay, startOfDay } from 'date-fns';

import { ASSIGNEE_NOT_ASSIGNED } from '~/pages/financing-management/constants';
import {
  createFilter,
  createFilterPayload,
  FilterType,
} from '~/utils/gql-helpers';

import type { CountryOption, FilterConfig } from './types';

export const formToMeta = (
  ctrl: FilterConfig,
  countryOptions: CountryOption[],
) => {
  const filters = [
    createFilter(
      'order.retailCountry',
      FilterType.IN,
      ctrl.retailCountry?.length
        ? ctrl.retailCountry
        : countryOptions.map((it) => it.value),
    ),
  ];

  if (ctrl.flowType) {
    filters.push(createFilter('flowType', FilterType.EQUAL, ctrl.flowType));
  }

  if (ctrl.financingType) {
    filters.push(
      createFilter(
        'financingType',
        ctrl.financingType === 'NONE' ? FilterType.IS_NULL : FilterType.EQUAL,
        ctrl.financingType !== 'NONE' ? ctrl.financingType : undefined,
      ),
    );
  }

  if (ctrl.status?.length) {
    filters.push(createFilter('status', FilterType.IN, ctrl.status));
  }

  if (ctrl.applicationType) {
    filters.push(
      createFilter('applicationType', FilterType.EQUAL, ctrl.applicationType),
    );
  }

  if (ctrl.externalFinancingRefNumber) {
    filters.push(
      createFilter(
        'externalFinancingRefNumber',
        FilterType.EQUAL,
        ctrl.externalFinancingRefNumber.trim(),
      ),
    );
  }

  if (ctrl.orderNumber) {
    filters.push(
      createFilter(
        'order.orderNumber',
        FilterType.EQUAL,
        ctrl.orderNumber.trim().toUpperCase(),
      ),
    );
  }

  if (ctrl.stockNumber) {
    filters.push(
      createFilter(
        'order.stockNumber',
        FilterType.EQUAL,
        ctrl.stockNumber.trim().toUpperCase(),
      ),
    );
  }

  if (ctrl.orderState?.length) {
    filters.push(createFilter('order.state', FilterType.IN, ctrl.orderState));
  }

  if (ctrl.paymentStatus?.length) {
    filters.push(
      createFilter('order.paymentStatus', FilterType.IN, ctrl.paymentStatus),
    );
  }

  if (ctrl.financingCreatedOnStart) {
    filters.push(
      createFilter(
        'createdAt',
        FilterType.GREATER_THAN_OR_EQUAL,
        startOfDay(ctrl.financingCreatedOnStart),
      ),
    );
  }

  if (ctrl.financingCreatedOnEnd) {
    filters.push(
      createFilter(
        'createdAt',
        FilterType.LESS_THAN_OR_EQUAL,
        endOfDay(ctrl.financingCreatedOnEnd),
      ),
    );
  }

  if (ctrl.planType) {
    filters.push(createFilter('contractType', FilterType.EQUAL, ctrl.planType));
  }

  if (ctrl.customerLastName) {
    filters.push(
      createFilter('customer.lastName', FilterType.LIKE, ctrl.customerLastName),
    );
  }

  if (ctrl.customerEmail) {
    filters.push(
      createFilter('customer.email', FilterType.LIKE, ctrl.customerEmail),
    );
  }

  if (ctrl.insurance) {
    const { ppi, gap } = {
      ppi: { ppi: true, gap: false },
      gap: { ppi: false, gap: true },
      'ppi+gap': { ppi: true, gap: true },
    }[ctrl.insurance] ?? { ppi: false, gap: false };

    filters.push(createFilter('ppi', FilterType.EQUAL, ppi));
    filters.push(createFilter('gap', FilterType.EQUAL, gap));
  }

  if (ctrl.verificationState) {
    filters.push(
      createFilter(
        'verificationFormCheckListState',
        FilterType.EQUAL,
        ctrl.verificationState,
      ),
    );
  }

  if (ctrl.financingProvider?.length) {
    filters.push(
      createFilter('financingProvider', FilterType.IN, ctrl.financingProvider),
    );
  }

  if (ctrl.assignee?.length) {
    const assigneeFilters = [];

    if (ctrl.assignee.includes(ASSIGNEE_NOT_ASSIGNED)) {
      assigneeFilters.push(
        createFilter('financingAssignee.id', FilterType.IS_NULL, null),
      );
    }

    const selectedAgents = ctrl.assignee.filter(
      (it) => it !== ASSIGNEE_NOT_ASSIGNED,
    );

    if (selectedAgents.length) {
      assigneeFilters.push(
        createFilter('financingAssignee.id', FilterType.IN, selectedAgents),
      );
    }

    filters.push(
      assigneeFilters.length > 1
        ? createFilter(null, FilterType.OR, assigneeFilters)
        : assigneeFilters[0],
    );
  }

  if (ctrl.salesAgent?.length) {
    filters.push(
      createFilter(
        'order.salesAgentAssignedTo',
        FilterType.IN,
        ctrl.salesAgent,
      ),
    );
  }

  if (ctrl.bankReleaseSigned) {
    filters.push(
      createFilter(
        'order.customerSignedBankRelease',
        FilterType.EQUAL,
        ctrl.bankReleaseSigned === 'YES',
      ),
    );
  }

  if (ctrl.handoverDateStart) {
    filters.push(
      createFilter(
        'order.handoverDate',
        FilterType.GREATER_THAN_OR_EQUAL,
        startOfDay(ctrl.handoverDateStart),
      ),
    );
  }

  if (ctrl.handoverDateEnd) {
    filters.push(
      createFilter(
        'order.handoverDate',
        FilterType.LESS_THAN_OR_EQUAL,
        endOfDay(ctrl.handoverDateEnd),
      ),
    );
  }

  if (ctrl.bankContractSignedOnStart) {
    filters.push(
      createFilter(
        'bankContractSignedOn',
        FilterType.GREATER_THAN_OR_EQUAL,
        startOfDay(ctrl.bankContractSignedOnStart),
      ),
    );
  }

  if (ctrl.bankContractSignedOnEnd) {
    filters.push(
      createFilter(
        'bankContractSignedOn',
        FilterType.LESS_THAN_OR_EQUAL,
        endOfDay(ctrl.bankContractSignedOnEnd),
      ),
    );
  }

  if (ctrl.bankSubmissionDateStart) {
    filters.push(
      createFilter(
        'submittedToBankOn',
        FilterType.GREATER_THAN_OR_EQUAL,
        startOfDay(ctrl.bankSubmissionDateStart),
      ),
    );
  }

  if (ctrl.bankSubmissionDateEnd) {
    filters.push(
      createFilter(
        'submittedToBankOn',
        FilterType.LESS_THAN_OR_EQUAL,
        endOfDay(ctrl.bankSubmissionDateEnd),
      ),
    );
  }

  return createFilterPayload({
    page: ctrl.page - 1,
    pageSize: ctrl.pageSize,
    filter:
      filters.length > 1
        ? createFilter(null, FilterType.AND, filters)
        : (filters[0] ?? null),
  });
};
