import { ReactNode, useState } from 'react';
import { Popover, Form, FormInstance, Button, Space, FormProps } from 'antd';

interface PopoverFormProps<Values>
  extends Omit<FormProps, 'title' | 'form' | 'onFinish'> {
  children: ReactNode;
  title: ReactNode;
  formFields: ReactNode;
  formInstance: FormInstance<Values>;
  formProps?: Omit<FormProps, 'title' | 'form' | 'onFinish'>;
  submitText?: ReactNode;
  submitDisabled?: boolean;
  onSubmit: (values: Values) => Promise<unknown>;
  onPopoverVisibilityChange: (isVisible: boolean) => void;
}

export const PopoverForm = <Values extends object>({
  title,
  children,
  formFields,
  formInstance,
  formProps,
  submitText,
  submitDisabled,
  onSubmit,
  onPopoverVisibilityChange,
}: PopoverFormProps<Values>) => {
  const [isPopoverVisible, setPopoverVisible] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);

  const handleSubmit = async (values: Values) => {
    setSubmitting(true);
    try {
      await onSubmit(values);
      setPopoverVisible(false);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Popover
      trigger="click"
      title={title}
      content={
        <div>
          <Form {...formProps} form={formInstance} onFinish={handleSubmit}>
            <Space direction="vertical">
              {formFields}
              <Space
                style={{
                  width: '100%',
                  justifyContent: 'space-between',
                }}
              >
                <Button
                  data-qa-selecto="popover-form-cancel"
                  onClick={() => setPopoverVisible(false)}
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  data-qa-selector="popover-form-save"
                  disabled={isSubmitting || submitDisabled}
                  loading={isSubmitting}
                >
                  {submitText ?? 'Save'}
                </Button>
              </Space>
            </Space>
          </Form>
        </div>
      }
      visible={isPopoverVisible}
      onVisibleChange={(isVisible) => {
        setPopoverVisible(isVisible);
        onPopoverVisibilityChange(isVisible);
      }}
    >
      {children}
    </Popover>
  );
};
