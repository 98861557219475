import { useCallback } from 'react';
import { FinancingStatus } from '@retail/order-constants';

import {
  RetailOrderFinanceCancelReason,
  RetailOrderFinanceRejectReason,
  RetailFinancingApplicationCustomerActionRequiredReason,
  useUpdateOrderFinancingStatusMutation,
} from '~/apollo/gql-types';

export const useUpdateFinancingStatus = () => {
  const [mutate] = useUpdateOrderFinancingStatusMutation();

  return useCallback(
    async ({
      orderId,
      status,
      reason,
      comment,
    }: {
      orderId: string;
      status: string;
      reason: string | null;
      comment: string | null;
    }) => {
      const { data } = await mutate({
        variables: {
          orderId,
          status,
          cancelReason:
            status === FinancingStatus.CANCELED
              ? (reason as RetailOrderFinanceCancelReason)
              : null,
          rejectReason:
            status === FinancingStatus.REJECTED
              ? (reason as RetailOrderFinanceRejectReason)
              : null,
          customerActionRequiredReason:
            status === FinancingStatus.CUSTOMER_ACTION_REQUIRED
              ? (reason as RetailFinancingApplicationCustomerActionRequiredReason)
              : null,
          comment: comment || null,
        },
      });

      return data.isUpdated;
    },
    [mutate],
  );
};
