import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';

import {
  CustomerActionRequiredModal,
  CustomerActionRequiredModalProps,
} from '~/features/CustomerActionRequiredModal';
import { RootContext } from '~/providers/RootContext';

import './setup';
import Root from './components/root';

const errorBoundary = (err: Error, info: React.ErrorInfo) => {
  console.error(err, info);

  return <>Error</>;
};

export const customerActionRequiredModal = singleSpaReact({
  React,
  ReactDOM,
  renderType: 'render',
  rootComponent: ({
    open,
    ...props
  }: CustomerActionRequiredModalProps & { open: boolean }) => (
    <RootContext>
      {open && <CustomerActionRequiredModal {...props} />}
    </RootContext>
  ),
  errorBoundary,
});

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  renderType: 'render',
  rootComponent: Root,
  // TODO: Switch to "errorBoundary (err, info, props) {" when it starts being used
  errorBoundary,
});

export const { bootstrap, mount, unmount } = lifecycles;
