import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Alert,
  Form,
  Select,
  InputNumber,
  Space,
  Input,
  Modal,
  Spin,
} from 'antd';
import { CurrencyCodes, unitsFromMajorToMinor } from '@retail/currency';
import { FinancingContractType } from '@retail/order-constants';

import {
  useOrderDetailsQuery,
  useFetchFinancingCustomerActionRequiredReasonsQuery,
  RetailFinancingApplicationCustomerActionRequiredReason,
  useGetNewFinancingTermPreConditionsQuery,
} from '~/apollo/gql-types';
import { AmountInput } from '~/components/AmountInput';
import { addQaIdForSelectOptions, selectQaSelectors } from '~/utils/antd';
import { Country } from '~/utils/accessible-countries';

import {
  getFinancingPeriods,
  cappedNumber,
  getWithDiscountByContractType,
} from './utils';
import { useSaveFinancingTerms } from './hooks/useSaveFinancingTerms';
import { ACTION_REQUIRED_REASON_LABEL, CONTRACT_TYPE_LABEL } from './constants';
import { FormValues } from './types';

export interface CustomerActionRequiredModalProps {
  orderId: string;
  financingApplicationId: string;
  onCancel?: () => void;
  onSubmitComplete?: (values: {
    reason: RetailFinancingApplicationCustomerActionRequiredReason;
    comment?: string | null;
  }) => void;
}

const FormItem = Form.Item;

export const CustomerActionRequiredModal = ({
  orderId,
  financingApplicationId,
  onCancel,
  onSubmitComplete,
}: CustomerActionRequiredModalProps) => {
  const { t } = useTranslation();
  const [submitting, setSubmitting] = useState(false);
  const [submissionError, setSubmissionError] = useState(null);

  const { data: { order } = {} } = useOrderDetailsQuery({
    variables: {
      orderId,
    },
  });
  const { adItems, retailCountry } = order ?? {};
  const adId = adItems?.[0]?.ad?.id;

  const { data: { reasons = [] } = {} } =
    useFetchFinancingCustomerActionRequiredReasonsQuery({
      fetchPolicy: 'cache-first',
    });
  const saveFinancingTerms = useSaveFinancingTerms({
    financingApplicationId,
  });

  const [formInstance] = Form.useForm<FormValues>();
  const selectedReason = Form.useWatch('reason', formInstance);
  const selectedCreditAmount = Form.useWatch(
    'creditAmountMinorUnits',
    formInstance,
  );
  const selectedCreditPeriod = Form.useWatch('creditPeriod', formInstance);
  const selectedContractType = Form.useWatch('contractType', formInstance);

  const {
    data: preConditionData,
    loading: isPreConditionsLoading,
    refetch: refetchPreConditions,
  } = useGetNewFinancingTermPreConditionsQuery({
    variables: {
      adId,
      orderId,
    },
    skip:
      !adId ||
      !orderId ||
      selectedReason !==
        RetailFinancingApplicationCustomerActionRequiredReason.ChangeOfFinancingTerms,
    fetchPolicy: 'cache-first',
  });

  const financingConditions =
    preConditionData?.financingCalculator?.financingConditions ?? {};
  const {
    availableContractTypes,
    minPeriod,
    maxPeriod,
    periodStep,
    currencyCode: currency,
    totalOrderPriceGross,
    minFinancingAmount,
    maxFinancingAmount,
    minCashAmount,
  } = financingConditions;
  const maxCashAmount = totalOrderPriceGross - minFinancingAmount;

  const handleChange = async (
    change:
      | { contractType: string }
      | { creditAmount: number }
      | { creditPeriod: number }
      | { cashAmount: number },
  ) => {
    let payload = {
      adId,
      orderId,
      contractType: selectedContractType,
      financingAmount: selectedCreditAmount,
      financingPeriod: selectedCreditPeriod,
      withDiscount:
        retailCountry === Country.ES
          ? getWithDiscountByContractType(selectedContractType)
          : undefined,
    };

    if ('contractType' in change) {
      const withDiscount =
        retailCountry === Country.ES
          ? getWithDiscountByContractType(change.contractType)
          : undefined;

      if (change.contractType === FinancingContractType.CLASSIC_WITH_DISCOUNT) {
        formInstance.setFieldsValue({
          creditAmountMinorUnits: maxFinancingAmount,
          depositAmountMinorUnits: minCashAmount,
        });
      }
      payload = {
        ...payload,
        withDiscount,
        contractType: change.contractType,
      };
    }

    if ('creditAmount' in change) {
      const boundedValue = cappedNumber(
        minFinancingAmount,
        maxFinancingAmount,
        unitsFromMajorToMinor(change.creditAmount),
      );
      const cashAmount = totalOrderPriceGross - boundedValue;
      formInstance.setFieldsValue({ depositAmountMinorUnits: cashAmount });

      payload = {
        ...payload,
        financingAmount: boundedValue,
      };
    }

    if ('creditPeriod' in change) {
      payload = {
        ...payload,
        financingPeriod: change.creditPeriod,
      };
    }

    if ('cashAmount' in change) {
      const boundedValue = cappedNumber(
        minCashAmount,
        maxCashAmount,
        unitsFromMajorToMinor(change.cashAmount),
      );
      const financingAmount = totalOrderPriceGross - boundedValue;

      payload = {
        ...payload,
        financingAmount,
      };
    }

    try {
      const { data } = await refetchPreConditions(payload);

      if (
        data?.financingCalculator?.financingConditions &&
        payload.contractType &&
        payload.financingPeriod &&
        payload.financingAmount
      ) {
        updateFormFields(data.financingCalculator.financingConditions);
      }
    } catch (err) {
      setSubmissionError(err?.graphQLErrors?.[0]?.message);
    }
  };

  const updateFormFields = (financingConditions) => {
    formInstance.setFieldsValue({
      balloonAmountMinorUnits: financingConditions.balloonAmountNoRounding,
      interestRate: financingConditions.nominalInterestRate,
      monthlyPaymentAmountMinorUnits:
        financingConditions.monthlyInstalmentWithInsuranceNoRounding,
      creditAmountMinorUnits: financingConditions.financingAmount,
      creditPeriod: financingConditions.financingPeriod,
      depositAmountMinorUnits:
        totalOrderPriceGross - financingConditions.financingAmount,
    });
  };

  const handleSubmit = async (values: FormValues) => {
    const { reason, comment, ...newTerms } = values;
    setSubmitting(true);

    try {
      if (
        reason ===
        RetailFinancingApplicationCustomerActionRequiredReason.ChangeOfFinancingTerms
      ) {
        await saveFinancingTerms(newTerms);
      }
      onSubmitComplete({
        reason,
        comment,
      });
    } catch (error) {
      setSubmissionError(error?.graphQLErrors?.[0]?.message);
    } finally {
      setSubmitting(false);
    }
  };

  const translatedReasons = reasons.map((reason) => ({
    value: reason,
    label: t(ACTION_REQUIRED_REASON_LABEL[reason] ?? reason),
  }));
  const contractTypeOptions =
    availableContractTypes?.map((contractType) => ({
      value: contractType,
      label: t(CONTRACT_TYPE_LABEL[contractType] ?? contractType),
    })) ?? [];
  const creditPeriodOptions = getFinancingPeriods({
    min: minPeriod,
    max: maxPeriod,
    step: periodStep,
  }).map((value) => ({
    value,
    label: `${value} ${t('bo.contractTab.months')}`,
  }));
  const currencyCode = currency as CurrencyCodes;
  const withDiscountContractType =
    selectedContractType === FinancingContractType.CLASSIC_WITH_DISCOUNT;
  const withoutDiscountContractType =
    selectedContractType === FinancingContractType.CLASSIC_WITHOUT_DISCOUNT;

  return (
    <Modal
      visible
      closable={false}
      style={{ width: 'min-content' }}
      title={t('bo.contractTab.customerActionRequiredModal.title')}
      cancelText="Cancel"
      onCancel={onCancel}
      centered
      okText={
        selectedReason ===
        RetailFinancingApplicationCustomerActionRequiredReason.ChangeOfFinancingTerms
          ? t('bo.contractTab.customerActionRequiredModal.submit')
          : t('bo.admin.buttons.save')
      }
      okButtonProps={{
        htmlType: 'submit',
        form: 'customer-action-required-form',
        loading: submitting,
      }}
    >
      <Form
        id="customer-action-required-form"
        form={formInstance}
        layout="vertical"
        labelAlign="left"
        onFinish={handleSubmit}
      >
        {submissionError && (
          <Alert
            message={submissionError}
            type="error"
            showIcon
            closable
            onClose={() => setSubmissionError(null)}
          />
        )}
        <Space direction="vertical" style={{ width: '100%' }}>
          <FormItem
            name="reason"
            label={t('bo.contractTab.customerActionRequiredModal.reason')}
            rules={[{ required: true }]}
            required
          >
            <Select
              options={addQaIdForSelectOptions(translatedReasons)}
              {...selectQaSelectors('reason-for-status')}
            />
          </FormItem>
          {selectedReason ===
            RetailFinancingApplicationCustomerActionRequiredReason.CoborrowerRequired && (
            <Alert
              style={{
                marginTop: '12px',
              }}
              type="warning"
              message={t(
                'bo.contractTab.customerActionRequiredModal.coborrowerRequied.message',
              )}
            />
          )}
          {selectedReason ===
            RetailFinancingApplicationCustomerActionRequiredReason.ChangeOfFinancingTerms &&
            (isPreConditionsLoading ? (
              <Space
                direction="vertical"
                align="center"
                style={{ width: '100%' }}
              >
                <Spin />
              </Space>
            ) : (
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr',
                  gap: '16px',
                  width: '100%',
                }}
              >
                <FormItem
                  name="contractType"
                  label="Contract Type"
                  rules={[{ required: true }]}
                  required
                >
                  <Select
                    options={addQaIdForSelectOptions(contractTypeOptions)}
                    onChange={(contractType) => handleChange({ contractType })}
                    {...selectQaSelectors('contract-type')}
                  />
                </FormItem>
                <FormItem
                  name="creditPeriod"
                  label={t('bo.contractTab.paymentDetails.creditPeriod')}
                  rules={[{ required: true }]}
                  required
                >
                  <Select
                    options={addQaIdForSelectOptions(creditPeriodOptions)}
                    onChange={(creditPeriod) => handleChange({ creditPeriod })}
                    {...selectQaSelectors('creditPeriod')}
                  />
                </FormItem>
                <FormItem
                  name="creditAmountMinorUnits"
                  label={t('bo.contractTab.paymentDetails.creditAmount')}
                  rules={[{ required: true }]}
                  required
                >
                  <AmountInput
                    currencyCode={currencyCode}
                    precision={0}
                    onBlur={({ target }) =>
                      handleChange({ creditAmount: Number(target.value) })
                    }
                    min={minFinancingAmount}
                    max={maxFinancingAmount}
                    disabled={withDiscountContractType}
                    qaSelector="creditAmount"
                  />
                </FormItem>
                <FormItem
                  name="depositAmountMinorUnits"
                  label="Downpayment"
                  rules={[{ required: true }]}
                  required
                >
                  <AmountInput
                    currencyCode={currencyCode}
                    precision={0}
                    min={minCashAmount}
                    max={maxCashAmount}
                    disabled={withDiscountContractType}
                    onBlur={({ target }) =>
                      handleChange({ cashAmount: Number(target.value) })
                    }
                    qaSelector="downpayment"
                  />
                </FormItem>

                <FormItem
                  name="monthlyPaymentAmountMinorUnits"
                  label={t('bo.contractTab.paymentDetails.monthlyPayment')}
                  rules={[{ required: true }]}
                  required
                >
                  <AmountInput
                    currencyCode={currencyCode}
                    min={100}
                    max={999999}
                    qaSelector="monthlyPayment"
                    disabled={
                      withDiscountContractType || withoutDiscountContractType
                    }
                  />
                </FormItem>
                <FormItem
                  name="interestRate"
                  label={t('bo.contractTab.paymentDetails.interestRate')}
                  rules={[{ required: true }]}
                  required
                >
                  <InputNumber
                    addonBefore="%"
                    step={0.01}
                    precision={2}
                    min={0.01}
                    max={99.99}
                    disabled={withDiscountContractType}
                    style={{ width: '100%' }}
                  />
                </FormItem>

                {selectedContractType === FinancingContractType.BALLOON && (
                  <FormItem
                    name="balloonAmountMinorUnits"
                    label={t('bo.contractTab.paymentDetails.balloonPayment')}
                    rules={[{ required: true }]}
                    required
                  >
                    <AmountInput
                      currencyCode={currencyCode}
                      min={1}
                      max={totalOrderPriceGross}
                      qaSelector="balloonAmountMinorUnits"
                    />
                  </FormItem>
                )}
              </div>
            ))}
          {selectedReason ===
            RetailFinancingApplicationCustomerActionRequiredReason.Other && (
            <FormItem
              name="comment"
              label={t('bo.contractTab.customerActionRequiredModal.comment')}
              rules={[{ required: true }]}
              required
            >
              <Input.TextArea rows={4} />
            </FormItem>
          )}
        </Space>
      </Form>
    </Modal>
  );
};
