import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { RootContext } from '~/providers/RootContext';

import routes from '../../routes';

import './styles.less';

export default function Root() {
  return (
    <div id="financing-management">
      <RootContext>
        <BrowserRouter>
          <Routes>
            {routes.map(({ route, element }) => (
              <Route key={route.ROUTE} path={route.ROUTE} element={element} />
            ))}
          </Routes>
        </BrowserRouter>
      </RootContext>
    </div>
  );
}
