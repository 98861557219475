import { useMemo } from 'react';

import {
  FetchFinancingRelatedOptionsQuery,
  useFetchFinancingRelatedOptionsQuery,
  UserProjection as User,
} from '~/apollo/gql-types';
import { ASSIGNEE_NOT_ASSIGNED } from '~/pages/financing-management/constants';

import { formatFullName } from '../utils';

type Option = Record<'value' | 'label', string>;

interface PerCountryFinancingOptions {
  financingProvider?: null | {
    items?: null | string[];
  };
}

const makeAgentOption = (
  it: Pick<User, 'id' | 'firstName' | 'lastName'>,
): Option => ({
  value: it.id,
  label: formatFullName(it) ?? '-',
});

const makeFinancingRelatedOptions = (
  input: FetchFinancingRelatedOptionsQuery,
) => {
  const perCountry: Record<string, PerCountryFinancingOptions> =
    input?.externalFinancing?.options ?? {};
  const nonUniqFinancingProviders = Object.values(perCountry).flatMap(
    (it) => it.financingProvider?.items ?? [],
  );
  const financingProviders = Array.from(
    new Set(nonUniqFinancingProviders),
    (it) => ({ value: it, label: it }),
  );

  const saleAgents = input?.salesAgents?.map(makeAgentOption) ?? [];
  const financingAgentsByCountry =
    input?.financingAgents?.reduce<Record<string, Option[]>>(
      (byCountry, agent) => {
        if (agent.country) {
          (byCountry[agent.country] ??= []).push(makeAgentOption(agent));
        }

        return byCountry;
      },
      {},
    ) ?? {};

  const financingAgents = [
    { value: ASSIGNEE_NOT_ASSIGNED, label: 'Unassigned' },
    ...Object.values(financingAgentsByCountry).flat(),
  ];

  return {
    financingProviders,
    saleAgents,
    financingAgents,
    financingAgentsByCountry,
  };
};

export const useFinancingRelatedOptions = () => {
  const { loading, data } = useFetchFinancingRelatedOptionsQuery({
    errorPolicy: 'all',
  });

  return {
    loading,
    ...useMemo(() => makeFinancingRelatedOptions(data), [data]),
  };
};
