import { FinancingContractType } from '@retail/order-constants';

import { RetailFinancingApplicationCustomerActionRequiredReason as ActionRequiredReason } from '~/apollo/gql-types';

export const ACTION_REQUIRED_REASON_LABEL: Record<
  ActionRequiredReason,
  string
> = {
  CHANGE_OF_FINANCING_TERMS:
    'bo.orderOverview.searchForm.field.finActionRequiredMessage.changeOfTerms',
  COBORROWER_REQUIRED:
    'bo.orderOverview.searchForm.field.finActionRequiredMessage.coborrowerRequired',
  OTHER: 'bo.orderOverview.searchForm.field.finActionRequiredMessage.other',
};

export const CONTRACT_TYPE_LABEL: Record<FinancingContractType, string> = {
  CLASSIC: 'bo.contractTab.contractType.classic',
  BALLOON: 'bo.contractTab.contractType.balloon',
  FINANCIAL_LEASE: 'bo.contractTab.contractType.financialLease',
  CLASSIC_WITH_DISCOUNT: 'bo.contractTab.contractType.classicWithDiscount',
  CLASSIC_WITHOUT_DISCOUNT:
    'bo.contractTab.contractType.classicWithoutDiscount',
};
