import { useFetchFinancingDataForStatusTransitionQuery } from '~/apollo/gql-types';
import { useHasPermission } from '~/hooks/acl/useHasPermission';
import {
  createFilter,
  createFilterPayload,
  FilterType,
} from '~/utils/gql-helpers';

import { getFinancingStatuses } from './getFinancingStatuses';

export const useFinancingDataForStatusTransition = ({
  orderId,
  financingId,
  financingType,
  currentStatus,
  skip,
}: {
  orderId: string;
  financingId: string;
  financingType: string;
  currentStatus: string;
  skip?: boolean;
}) => {
  const hasPermission = useHasPermission();

  const {
    data,
    loading: isLoading,
    refetch,
  } = useFetchFinancingDataForStatusTransitionQuery({
    skip,
    fetchPolicy: 'cache-first',
    variables: {
      orderId,
      search: createFilterPayload({
        filter: createFilter('orderId', FilterType.EQUAL, orderId),
      }),
    },
  });

  return {
    isLoading,
    refetch,
    data: data
      ? {
          isActiveFinancing: data.financingApplication?.id === financingId,
          availableStatuses: getFinancingStatuses({
            currentStatus,
            financingType,
            hasPermission,
            previousStatus: data.orderFinancingChangelog?.entities?.[1]?.status,
            financingFlowVersion:
              data.financingApplication?.financingFlowVersion,
          }),
          cancelReason: data.financingApplication?.cancelReason,
          rejectReason: data.financingApplication?.rejectReason,
          customerActionRequiredReason:
            data.financingApplication?.customerActionRequiredReason,
        }
      : null,
  };
};
