import { useMemo } from 'react';

import { useRetailFeaturesStatusV2Query } from '~/apollo/gql-types';
import { FEATURE_FLAG } from '~/constants/feature-flag';

const getEnvironment = () =>
  // eslint-disable-next-line no-undef
  (typeof __config === 'object' && __config?.APP_ENV?.toUpperCase()) || null;

export const useFeatureFlags = () => {
  const context = {
    environment: getEnvironment(),
  };

  const { data, loading } = useRetailFeaturesStatusV2Query({
    variables: {
      names: Object.values(FEATURE_FLAG),
      context,
    },
  });

  const featuresMap = useMemo(
    () =>
      data?.list.reduce<{ [key: string]: boolean }>(
        (acc, { enabled, name }) => {
          acc[name] = enabled;

          return acc;
        },
        {},
      ) ?? {},
    [data],
  );

  return { loading, features: featuresMap };
};
