import { FinancingStatus } from '@retail/order-constants';

export const financingApplicationStatuses = [
  {
    value: FinancingStatus.PENDING,
    label: 'Pending',
    color: 'default',
  },
  {
    value: FinancingStatus.VALIDATION,
    label: 'Validation',
    color: 'default',
  },
  {
    value: FinancingStatus.CUSTOMER_ACTION_REQUIRED,
    label: 'Customer action required',
    color: 'default',
  },
  {
    value: FinancingStatus.SUBMITTED_TO_BANK,
    label: 'Submitted to bank',
    color: 'default',
  },
  {
    value: FinancingStatus.PRE_APPROVED,
    label: 'Pre-approved',
    color: 'default',
  },
  {
    value: FinancingStatus.APPROVED,
    label: 'Approved',
    color: 'success',
  },
  {
    value: FinancingStatus.REJECTED,
    label: 'Rejected',
    color: 'danger',
  },
  {
    value: FinancingStatus.CANCELED,
    label: 'Canceled',
    color: 'default',
  },
  {
    value: FinancingStatus.COMPLETED,
    label: 'Completed',
    color: 'default',
  },
];
