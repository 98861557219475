import React, { memo, ReactNode } from 'react';
import { Form, Select, SelectProps } from 'antd';
import {
  DeepRequired,
  FieldErrorsImpl,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';

interface FSelectProps<T extends FieldValues> extends SelectProps<T> {
  controllerProps: UseControllerProps<T>;
  labelCol?: { [key: string]: string | number };
  required?: boolean;
  label?: ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: any;
  qaSelector?: string;
  checker?: (value, options) => never;
  errors?: FieldErrorsImpl<DeepRequired<unknown>>;
}

const defaultChecker = (value) => value;
const defaultFilterOption = (input, option) =>
  option.children.toLowerCase().includes(input.toLowerCase());

export const FSelect = memo<FSelectProps<FieldValues>>(
  ({
    label,
    errors,
    options,
    labelCol,
    qaSelector,
    controllerProps,
    required = false,
    checker = defaultChecker,
    filterOption = defaultFilterOption,
    ...attr
  }) => {
    const { field } = useController(controllerProps);
    const error = errors?.[controllerProps?.name];
    return (
      <Form.Item
        label={label}
        required={required}
        labelCol={labelCol}
        help={error ? error?.message : null}
        validateStatus={error ? 'error' : null}
        data-qa-selector={`${qaSelector}-wrapper`}
      >
        <Select
          {...field}
          filterOption={filterOption}
          value={checker(field.value, options)}
          data-qa-selector={`${qaSelector}-field`}
          onChange={(value = null) => field.onChange(value)}
          {...attr}
          options={options.map((it) => ({
            ...it,
            'data-qa-selector': 'option',
            'data-qa-selector-field': `${qaSelector}-field`,
            'data-qa-selector-option-value': it.value,
            'data-qa-selector-option-label': it.label,
          }))}
        />
      </Form.Item>
    );
  },
);

export default Select;
