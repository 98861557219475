import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FinancingStatus } from '@retail/order-constants';
import { Alert, Button, Form, Space, Spin, Tag, Tooltip } from 'antd';
import { WarningOutlined } from '@ant-design/icons';

import {
  FinancingFragment as Financing,
  RetailFinancingApplicationCustomerActionRequiredReason as CustomerActionRequiredReason,
} from '~/apollo/gql-types';
import { FEATURE_FLAG } from '~/constants/feature-flag';
import { CustomerActionRequiredModal } from '~/features/CustomerActionRequiredModal';
import { ACTION_REQUIRED_REASON_LABEL } from '~/features/CustomerActionRequiredModal/constants';
import { financingApplicationStatuses } from '~/constants/financing-application-statuses';
import { PERMISSION } from '~/constants/permission';
import { DOMAIN } from '~/constants/domain';
import useCheckPermissions from '~/hooks/acl/useCheckPermissions';
import { useFeatureFlags } from '~/hooks/useFeatureFlags';

import { PopoverForm } from '../popover-form';

import { useFinancingDataForStatusTransition } from './useFinancingDataForStatusTransition';
import { FormFields } from './FormFields';
import { FormValues } from './types';

type Props = {
  item: Financing;
  qaSelector?: string;
  onSave: (
    values: FormValues & { orderId: string; financingId: string },
  ) => Promise<unknown>;
};

export const ApplicationState = ({ item, qaSelector, onSave }: Props) => {
  const { t } = useTranslation();
  const [formInstance] = Form.useForm<FormValues>();
  const canUpdateFinancing = useCheckPermissions({
    allow: PERMISSION.MUTATION_UPDATE_ORDER_FINANCING,
    domain: DOMAIN.ORDER_MANAGEMENT,
  }).isAllowed;

  const {
    status: currentStatus,
    customerActionRequiredReason,
    orderId,
    financingId,
  } = item;

  const [isPopupOpen, setPopupOpen] = useState(false);
  const [
    isCustomerActionRequiredModalOpen,
    setIsCustomerActionRequiredModalOpen,
  ] = useState(false);

  const {
    data: transitionData,
    isLoading,
    refetch: refetchData,
  } = useFinancingDataForStatusTransition({
    orderId,
    financingId,
    financingType: item.financingType,
    currentStatus,
    skip: !isPopupOpen,
  });

  const {
    features: {
      [FEATURE_FLAG.CUSTOMER_ACTION_REQUIRED_REASONS]:
        isCustomerActionRequiredReasonsEnabled,
    },
  } = useFeatureFlags();

  const handleSubmit = async (values: FormValues) => {
    if (
      values.status === FinancingStatus.CUSTOMER_ACTION_REQUIRED &&
      isCustomerActionRequiredReasonsEnabled
    ) {
      setIsCustomerActionRequiredModalOpen(true);
    } else {
      onSave({ ...values, orderId, financingId }).finally(() => refetchData());
    }
  };

  const handleCustomerActionSubmit = async (values: {
    reason: CustomerActionRequiredReason;
    comment: string;
  }) => {
    await onSave({
      ...values,
      orderId,
      financingId,
      status: FinancingStatus.CUSTOMER_ACTION_REQUIRED,
    }).finally(() => refetchData());
    setIsCustomerActionRequiredModalOpen(false);
  };

  const statusLabel =
    financingApplicationStatuses.find((it) => it.value === currentStatus)
      ?.label ?? currentStatus;

  const selectedStatus = Form.useWatch<string>('status', formInstance);
  const actionRequiredReason =
    transitionData?.customerActionRequiredReason ||
    customerActionRequiredReason;
  const isActionRequired =
    currentStatus === FinancingStatus.CUSTOMER_ACTION_REQUIRED &&
    !!actionRequiredReason &&
    actionRequiredReason !== CustomerActionRequiredReason.Other;

  if (canUpdateFinancing) {
    const isActiveFinancing = transitionData?.isActiveFinancing;
    return (
      <>
        <PopoverForm
          title="Change status"
          formProps={{
            labelAlign: 'left',
            labelCol: { span: 8 },
            wrapperCol: { span: 16 },
          }}
          formInstance={formInstance}
          formFields={(() => {
            if (isLoading) {
              return (
                <Space
                  direction="vertical"
                  align="center"
                  style={{ width: '100%' }}
                >
                  <Spin />
                </Space>
              );
            }

            if (!transitionData) {
              return null;
            }

            return isActiveFinancing ? (
              <FormFields
                currentStatus={currentStatus}
                financingType={item.financingType}
                {...transitionData}
              />
            ) : (
              <Alert
                type="info"
                description="This financing application is not the latest for it's order"
              />
            );
          })()}
          submitDisabled={
            !isActiveFinancing || selectedStatus === currentStatus
          }
          submitText={
            selectedStatus === FinancingStatus.CUSTOMER_ACTION_REQUIRED &&
            isCustomerActionRequiredReasonsEnabled
              ? 'Continue'
              : undefined
          }
          onSubmit={handleSubmit}
          onPopoverVisibilityChange={(isVisible) => {
            setPopupOpen(isVisible);
            if (isVisible) {
              formInstance.setFieldsValue({
                status: currentStatus,
              });
            }
          }}
        >
          <Button
            size="small"
            type="primary"
            style={{ backgroundColor: '#222' }}
            data-qa-selector={qaSelector}
          >
            {statusLabel}
            {isActionRequired && (
              <span>
                <Tooltip
                  placement="bottom"
                  title={t(
                    ACTION_REQUIRED_REASON_LABEL[actionRequiredReason] ??
                      actionRequiredReason,
                  )}
                >
                  <WarningOutlined style={{ marginLeft: '5px' }} />
                </Tooltip>
              </span>
            )}
          </Button>
        </PopoverForm>
        {isCustomerActionRequiredModalOpen && (
          <CustomerActionRequiredModal
            orderId={orderId}
            financingApplicationId={financingId}
            onCancel={() => setIsCustomerActionRequiredModalOpen(false)}
            onSubmitComplete={handleCustomerActionSubmit}
          />
        )}
      </>
    );
  }

  return (
    <Tag color="#222" data-qa-selector={qaSelector}>
      {statusLabel}
    </Tag>
  );
};
