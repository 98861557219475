import { HTMLAttributes } from 'react';
import { CheckOutlined, MinusOutlined } from '@ant-design/icons';
import { Table, Tag, Typography } from 'antd';
import type { ColumnType, TableProps } from 'antd/es/table';
import { differenceInBusinessDays, format as formatDate } from 'date-fns';
import { FinancingStatus, FinancingType } from '@retail/order-constants';

import { DATE_TIME_FORMAT } from '~/constants/date';
import { formatCurrency } from '~/utils/currency';
import { FinancingFragment as Financing } from '~/apollo/gql-types';
import useCheckPermissions from '~/hooks/acl/useCheckPermissions';
import { DOMAIN } from '~/constants/domain';
import { PERMISSION } from '~/constants/permission';

import { useFinancingRelatedOptions } from '../hooks/useFinancingRelatedOptions';
import { useFinancingAgent } from '../hooks/useFinancingAgent';
import { useBankContract } from '../hooks/useBankContract';
import { useUpdateExternalFinancingRefNumber } from '../hooks/useUpdateExternalFinancingRefNumber';
import { useUpdateFinancingStatus } from '../hooks/useUpdateFinancingStatus';

import { Assignee } from './assignee';
import { BankContractSignedOn } from './bank-contract-signed-on';
import { ApplicationNumber } from './application-number';
import { ApplicationState } from './application-state';
import { CustomerName } from './customer-name';
import { FlowType } from './flow-type';
import { OrderNumber } from './order-number';
import { OrderState } from './order-state';
import { PaymentType } from './payment-type';
import { StockNumber } from './stock-number';
import cn from './styles.less';
import { TaskActions } from './task-actions';
import {
  ApplicationType as ApplicationTypeEnum,
  SortingConfig,
  TableColumn,
} from './types';
import {
  VerificationState as VerificationStateOption,
  TABLE_COLUMN_LABELS,
  FINANCING_TYPE_LABELS,
  PAYMENT_STATUS_LABELS,
} from './constants';
import { ApplicationType } from './application-type';
import { VerificationState } from './verification-state';
import { ExternalFinancingRefNumber } from './external-financing-ref-number';
import { AxaPolicyNotifications } from './AxaPolicyNotifications';
import { Comments } from './comments';

const { Paragraph } = Typography;

interface GetColumnsMapInput {
  allowSettingAssignee: boolean;
  allowMarkingAsSigned: boolean;
  allowUpdateExternalFinancingRefNumber: boolean;
  financingAgentsByCountry: Record<string, Record<'value' | 'label', string>[]>;
  onFinancingAgentAssign: (payload: {
    financingId: string;
    agent: null | Required<Financing['assignee']>;
  }) => Promise<void>;
  onBankContractMarkAsSigned: (payload: {
    financingId: string;
    date: string;
  }) => Promise<void>;
  onExternalFinancingRefNumberUpdate: (payload: {
    financingId: string;
    orderId: string;
    refNumber: string;
  }) => Promise<void>;
  onFinancingStatusUpdate: (payload: {
    financingId: string;
    orderId: string;
    status: string;
    reason: string | null;
    comment: string | null;
  }) => Promise<void>;
}

export interface FinancingTableProps {
  loading: boolean;
  columns: TableColumn[];
  sorting: undefined | SortingConfig;
  list: Financing[];
  onSortingChange: (sorting: null | SortingConfig) => void;
  onItemUpdate: (financingId: string, patch: Partial<Financing>) => void;
}

const qaTableCell = (column: TableColumn) => `table-cell-${column}`;

const getWorkingDaysUntilHandover = (
  now: Date,
  handoverDate: Date,
): undefined | number =>
  handoverDate >= now ? differenceInBusinessDays(handoverDate, now) : undefined;

const getColumnsMap = ({
  allowSettingAssignee,
  allowMarkingAsSigned,
  allowUpdateExternalFinancingRefNumber,
  financingAgentsByCountry,
  onFinancingAgentAssign,
  onBankContractMarkAsSigned,
  onExternalFinancingRefNumberUpdate,
  onFinancingStatusUpdate,
}: GetColumnsMapInput): Record<TableColumn, ColumnType<Financing>> => ({
  [TableColumn.Country]: {
    render: (_, { order }) =>
      order?.retailCountry ? (
        <Tag data-qa-selector={qaTableCell(TableColumn.Country)} color="#222">
          {order.retailCountry}
        </Tag>
      ) : (
        <MinusOutlined />
      ),
  },
  [TableColumn.FlowType]: {
    render: (_, { flowType }) =>
      flowType ? (
        <FlowType
          flowType={flowType}
          qaSelector={qaTableCell(TableColumn.FlowType)}
        />
      ) : (
        <MinusOutlined />
      ),
  },
  [TableColumn.FinancingType]: {
    render: (_, { financingType }) => (
      <span data-qa-selector={qaTableCell(TableColumn.FinancingType)}>
        {FINANCING_TYPE_LABELS[financingType] ?? financingType ?? (
          <MinusOutlined />
        )}
      </span>
    ),
  },
  [TableColumn.AppState]: {
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    render: (_, item) => (
      <ApplicationState
        item={item}
        qaSelector={qaTableCell(TableColumn.AppState)}
        onSave={onFinancingStatusUpdate}
      />
    ),
  },
  [TableColumn.AppType]: {
    render: (_, item) => (
      <ApplicationType
        orderId={item.orderId}
        applicationType={item.applicationType as ApplicationTypeEnum}
        qaSelector={qaTableCell(TableColumn.AppType)}
      />
    ),
  },
  [TableColumn.OrderState]: {
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    render: (_, { order }) =>
      order?.state ? (
        <OrderState
          state={order.state}
          qaSelector={qaTableCell(TableColumn.OrderState)}
        />
      ) : (
        <MinusOutlined />
      ),
  },
  [TableColumn.AppNumber]: {
    render: (_, item) => (
      <div data-qa-selector={qaTableCell(TableColumn.AppNumber)}>
        <ApplicationNumber
          orderId={item.orderId}
          applicationNumber={item.applicationNumber}
        />
      </div>
    ),
  },
  [TableColumn.ExternalFinancingRefNumber]: {
    render: (_, item) =>
      item.financingType === FinancingType.EXTERNAL ? (
        <ExternalFinancingRefNumber
          allowUpdate={allowUpdateExternalFinancingRefNumber}
          externalFinancingRefNumber={item.externalFinancingRefNumber}
          qaSelector={qaTableCell(TableColumn.ExternalFinancingRefNumber)}
          onSave={(refNumber: string) =>
            onExternalFinancingRefNumberUpdate({
              financingId: item.financingId,
              orderId: item.orderId,
              refNumber,
            })
          }
        />
      ) : (
        <MinusOutlined />
      ),
  },
  [TableColumn.OrderNumber]: {
    render: (_, { orderId, order }) => (
      <div data-qa-selector={qaTableCell(TableColumn.OrderNumber)}>
        {order?.orderNumber ? (
          <OrderNumber
            orderId={orderId}
            flowVersion={order.flowVersion}
            orderNumber={order.orderNumber}
          />
        ) : (
          <MinusOutlined />
        )}
      </div>
    ),
  },
  [TableColumn.StockNumber]: {
    render: (_, { order, adId }) => (
      <div data-qa-selector={qaTableCell(TableColumn.StockNumber)}>
        {adId && order?.stockNumber ? (
          <StockNumber adId={adId} stockNumber={order.stockNumber} />
        ) : (
          <MinusOutlined />
        )}
      </div>
    ),
  },
  [TableColumn.Assignee]: {
    render: (
      _,
      { financingId, orderId, status, assignee, order: { retailCountry } = {} },
    ) => (
      <Assignee
        allowSettingAssignee={
          allowSettingAssignee && status !== FinancingStatus.CANCELED
        }
        country={retailCountry}
        orderId={orderId}
        financingAgentsByCountry={financingAgentsByCountry}
        assignee={assignee}
        qaSelector={qaTableCell(TableColumn.Assignee)}
        onFinancingAgentAssign={(
          agent: null | Required<Financing['assignee']>,
        ) =>
          onFinancingAgentAssign({
            financingId,
            agent,
          })
        }
      />
    ),
  },
  [TableColumn.ContractType]: {
    render: (_, item) => (
      <span data-qa-selector={qaTableCell(TableColumn.ContractType)}>
        {item.contractType ?? <MinusOutlined />}
      </span>
    ),
  },
  [TableColumn.PriorityRank]: {
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    render: (_, { order }) => (
      <span data-qa-selector={qaTableCell(TableColumn.PriorityRank)}>
        {order?.priorityRank ?? <MinusOutlined />}
      </span>
    ),
  },
  [TableColumn.Cluster]: {
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    render: (_, item) => (
      <span data-qa-selector={qaTableCell(TableColumn.Cluster)}>
        {item.cluster ? (
          Array.from({ length: item.cluster }, () => '*')
        ) : (
          <MinusOutlined />
        )}
      </span>
    ),
  },
  [TableColumn.FinancingProvider]: {
    render: (_, item) => (
      <span data-qa-selector={qaTableCell(TableColumn.FinancingProvider)}>
        {item.financingProvider ?? <MinusOutlined />}
      </span>
    ),
  },
  [TableColumn.SalesAgent]: {
    render: (_, { order: { salesAgentAssignedTo: agent } = {} }) => {
      const fullName = [agent?.firstName, agent?.lastName]
        .filter(Boolean)
        .join(' ');

      return (
        <span data-qa-selector={qaTableCell(TableColumn.SalesAgent)}>
          {fullName || <MinusOutlined />}
        </span>
      );
    },
  },
  [TableColumn.InterestRate]: {
    render: (_, item) => {
      // Addresses BE inconsistency: 7.75 vs 0.0775
      const multiplier =
        item.interestRate != null && item.interestRate < 1 ? 100 : 1;

      return (
        <Paragraph data-qa-selector={qaTableCell(TableColumn.InterestRate)}>
          {item.interestRate ? (
            `${(item.interestRate * multiplier).toFixed(2)}%`
          ) : (
            <MinusOutlined />
          )}
        </Paragraph>
      );
    },
  },
  [TableColumn.CreditPeriod]: {
    render: (_, item) => (
      <Paragraph data-qa-selector={qaTableCell(TableColumn.CreditPeriod)}>
        {item.creditPeriod ?? <MinusOutlined />}
      </Paragraph>
    ),
  },
  [TableColumn.CreditAmount]: {
    render: (_, item) => (
      <Paragraph data-qa-selector={qaTableCell(TableColumn.CreditAmount)}>
        {item.order && item.creditAmountMinorUnits ? (
          formatCurrency(item.order.retailCountry, {
            currencyCode: item.currencyCode,
            amountMinorUnits: item.creditAmountMinorUnits,
          })
        ) : (
          <MinusOutlined />
        )}
      </Paragraph>
    ),
  },
  [TableColumn.PaymentType]: {
    render: (_, item) => (
      <PaymentType
        paymentType={item.paymentType}
        qaSelector={qaTableCell(TableColumn.PaymentType)}
      />
    ),
  },
  [TableColumn.PaymentStatus]: {
    render: (_, { order: { paymentStatus } }) => (
      <span data-qa-selector={qaTableCell(TableColumn.PaymentStatus)}>
        {paymentStatus ? (
          (PAYMENT_STATUS_LABELS[paymentStatus] ?? paymentStatus)
        ) : (
          <MinusOutlined />
        )}
      </span>
    ),
  },
  [TableColumn.MonthlyPaymentAmount]: {
    render: (_, item) => (
      <Paragraph
        data-qa-selector={qaTableCell(TableColumn.MonthlyPaymentAmount)}
      >
        {item.order && item.monthlyPaymentAmountMinorUnits ? (
          formatCurrency(item.order.retailCountry, {
            currencyCode: item.currencyCode,
            amountMinorUnits: item.monthlyPaymentAmountMinorUnits,
          })
        ) : (
          <MinusOutlined />
        )}
      </Paragraph>
    ),
  },
  [TableColumn.BalloonAmount]: {
    render: (_, item) => (
      <Paragraph data-qa-selector={qaTableCell(TableColumn.BalloonAmount)}>
        {item.order && item.balloonPayment ? (
          formatCurrency(item.order.retailCountry, {
            currencyCode: item.currencyCode,
            amountMinorUnits: item.balloonPayment,
          })
        ) : (
          <MinusOutlined />
        )}
      </Paragraph>
    ),
  },
  [TableColumn.CashAmount]: {
    render: (_, item) => (
      <Paragraph data-qa-selector={qaTableCell(TableColumn.CashAmount)}>
        {item.order && item.cashAmountMinorUnits != null ? (
          formatCurrency(item.order.retailCountry, {
            currencyCode: item.currencyCode,
            amountMinorUnits: item.cashAmountMinorUnits,
          })
        ) : (
          <MinusOutlined />
        )}
      </Paragraph>
    ),
  },
  [TableColumn.CustomerName]: {
    render: (_, item) => (
      <div data-qa-selector={qaTableCell(TableColumn.CustomerName)}>
        {item.customer ? (
          <CustomerName
            customer={item.customer}
            opportunity={item.opportunity}
          />
        ) : (
          <MinusOutlined />
        )}
      </div>
    ),
  },
  [TableColumn.Ppi]: {
    render: (_, { ppi }) => (
      <div data-qa-selector={qaTableCell(TableColumn.Ppi)}>
        {ppi ? (
          <CheckOutlined style={{ color: 'rgb(63, 182, 24)' }} />
        ) : (
          <MinusOutlined />
        )}
      </div>
    ),
  },
  [TableColumn.Gap]: {
    render: (_, { gap }) => (
      <div data-qa-selector={qaTableCell(TableColumn.Gap)}>
        {gap ? (
          <CheckOutlined style={{ color: 'rgb(63, 182, 24)' }} />
        ) : (
          <MinusOutlined />
        )}
      </div>
    ),
  },
  [TableColumn.VerificationForm]: {
    render: (_, { orderId, verificationState }) => (
      <VerificationState
        orderId={orderId}
        verificationState={verificationState as VerificationStateOption}
        qaSelector={qaTableCell(TableColumn.VerificationForm)}
      />
    ),
  },
  [TableColumn.AppCreationDate]: {
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    render: (_, item) => (
      <span data-qa-selector={qaTableCell(TableColumn.AppCreationDate)}>
        {item.createdAt ? (
          formatDate(new Date(item.createdAt), DATE_TIME_FORMAT)
        ) : (
          <MinusOutlined />
        )}
      </span>
    ),
  },
  [TableColumn.OrderCreationDate]: {
    render: (_, item) => (
      <span data-qa-selector={qaTableCell(TableColumn.OrderCreationDate)}>
        {item.order?.createdAt ? (
          formatDate(new Date(item.order.createdAt), DATE_TIME_FORMAT)
        ) : (
          <MinusOutlined />
        )}
      </span>
    ),
  },
  [TableColumn.HandoverDate]: {
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    render: (_, { order }) => (
      <span data-qa-selector={qaTableCell(TableColumn.HandoverDate)}>
        {order?.handoverDate ? (
          formatDate(new Date(order.handoverDate), DATE_TIME_FORMAT)
        ) : (
          <MinusOutlined />
        )}
      </span>
    ),
  },
  [TableColumn.WorkingDaysUntilHandover]: {
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    render: (_, { order: { handoverDate } = {} }) => {
      const workingDaysUntilHandover =
        handoverDate &&
        getWorkingDaysUntilHandover(new Date(), new Date(handoverDate));

      return (
        <span
          style={
            workingDaysUntilHandover != null && workingDaysUntilHandover < 5
              ? { color: '#cc1414', fontWeight: 'bold' }
              : undefined
          }
          data-qa-selector={qaTableCell(TableColumn.WorkingDaysUntilHandover)}
        >
          {workingDaysUntilHandover ?? <MinusOutlined />}
        </span>
      );
    },
  },
  [TableColumn.BankReleaseSigned]: {
    render: (_, { order }) => (
      <span data-qa-selector={qaTableCell(TableColumn.BankReleaseSigned)}>
        {order.customerSignedBankRelease ? (
          <CheckOutlined style={{ color: 'rgb(63, 182, 24)' }} />
        ) : (
          <MinusOutlined />
        )}
      </span>
    ),
  },
  [TableColumn.BankSubmissionDate]: {
    render: (_, { submittedToBankOn }) => (
      <span data-qa-selector={qaTableCell(TableColumn.BankSubmissionDate)}>
        {submittedToBankOn ? (
          formatDate(new Date(submittedToBankOn), DATE_TIME_FORMAT)
        ) : (
          <MinusOutlined />
        )}
      </span>
    ),
  },
  [TableColumn.BankContractSignedOn]: {
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    render: (_, item) => (
      <BankContractSignedOn
        allowMarkingAsSigned={allowMarkingAsSigned}
        financingStatus={item.status}
        bankContractSignedOn={item.bankContractSignedOn}
        orderCreatedOn={item.order?.createdAt}
        qaSelector={qaTableCell(TableColumn.BankContractSignedOn)}
        onMarkAsSigned={(date) =>
          onBankContractMarkAsSigned({
            financingId: item.financingId,
            date,
          })
        }
      />
    ),
  },
});

export const FinancingTable = ({
  loading,
  columns: visibleColumns,
  sorting,
  list,
  onSortingChange,
  onItemUpdate,
}: FinancingTableProps) => {
  const { financingAgentsByCountry } = useFinancingRelatedOptions();
  const { assignFinancingAgent } = useFinancingAgent();
  const { setBankContractSignedOn } = useBankContract();
  const updateExternalFinancingRefNumber =
    useUpdateExternalFinancingRefNumber();
  const updateFinancingStatus = useUpdateFinancingStatus();
  const columnsMap = getColumnsMap({
    financingAgentsByCountry,
    allowSettingAssignee: useCheckPermissions({
      domain: DOMAIN.ORDER_MANAGEMENT,
      allow: PERMISSION.MUTATION_ASSIGN_FINANCING_AGENT,
    }).isAllowed,
    allowMarkingAsSigned: useCheckPermissions({
      domain: DOMAIN.ORDER_MANAGEMENT,
      allow: PERMISSION.SET_BANK_CONTRACT_SIGNED_ON,
    }).isAllowed,
    allowUpdateExternalFinancingRefNumber: useCheckPermissions({
      domain: DOMAIN.ORDER_MANAGEMENT,
      allow: PERMISSION.MUTATION_CREATE_OR_UPDATE_EXTERNAL_FINANCING,
    }).isAllowed,

    onFinancingAgentAssign: ({ financingId, agent }) =>
      assignFinancingAgent({
        financingId,
        financingAgentId: agent?.id,
      }).then(() => {
        onItemUpdate(financingId, { assignee: agent });
      }),

    onBankContractMarkAsSigned: (payload) =>
      setBankContractSignedOn(payload).then(() => {
        onItemUpdate(payload.financingId, {
          bankContractSignedOn: payload.date,
        });
      }),

    onExternalFinancingRefNumberUpdate: ({ financingId, orderId, refNumber }) =>
      updateExternalFinancingRefNumber({ orderId, refNumber }).then((data) =>
        onItemUpdate(financingId, {
          externalFinancingRefNumber: data.externalFinancingRefNumber,
        }),
      ),

    onFinancingStatusUpdate: ({ financingId, ...payload }) =>
      updateFinancingStatus(payload).then((isSuccess) =>
        onItemUpdate(financingId, isSuccess ? { status: payload.status } : {}),
      ),
  });
  const columns: ColumnType<Financing>[] = [
    ...visibleColumns.map((column) => ({
      key: column,
      title: TABLE_COLUMN_LABELS[column],
      sortOrder: sorting?.column === column ? sorting.order : undefined,
      onHeaderCell: () =>
        ({
          'data-qa-selector': `table-header-cell-${column}`,
        }) as HTMLAttributes<HTMLElement>,
      ...columnsMap[column],
    })),
    {
      key: 'Actions',
      title: <span data-qa-selector="table-head-type">Risk card</span>,
      render: (_, item) => (
        <div className={cn.taskActionsWrap} data-qa-selector-name="taskActions">
          {item.orderId && item.selectedPlanId && (
            <TaskActions orderId={item.orderId} />
          )}
        </div>
      ),
    },
  ];

  const handleTableChange: TableProps<Financing>['onChange'] = (
    _,
    __,
    sorter,
    { action },
  ) => {
    if (action === 'sort' && !Array.isArray(sorter)) {
      const { columnKey, order } = sorter;

      onSortingChange(
        columnKey && order ? { column: columnKey as TableColumn, order } : null,
      );
    }
  };

  return (
    <Table
      bordered
      rowKey="financingId"
      className={cn.table}
      rowClassName="table-financing-management-row"
      pagination={false}
      scroll={{ x: true }}
      loading={loading}
      columns={columns}
      expandable={{
        expandedRowRender: (it) => (
          <>
            <AxaPolicyNotifications financing={it} />
            <Comments orderId={it.orderId} financingId={it.financingId} />
          </>
        ),
      }}
      dataSource={list}
      data-qa-selector="table-financing-management"
      onRow={(_, index) => ({
        className: `table-financing-management-row-${index}`,
      })}
      onChange={handleTableChange}
    />
  );
};
